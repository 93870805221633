import { Fancybox } from "@fancyapps/ui";

document.addEventListener('DOMContentLoaded', function () {

    // core/gallery : si les images sont cliquables on en fait une galerie Fancybox
    var all_galleries = document.querySelectorAll('figure.wp-block-gallery');
    all_galleries.forEach(function (gallery, fbg_index) {
        var all_clickable_images = gallery.querySelectorAll('figure.wp-block-image > a');
        all_clickable_images.forEach(function (clickable_image, index) {
            clickable_image.setAttribute('data-fancybox', 'fbg-' + fbg_index);
        });
    });
    Fancybox.bind("[data-fancybox]", {});

    // core/cover : parallax
    function core_cover_parallax() {
        $('.cameleon-has-parallax').each(function () {
            var hmm = $(window).scrollTop() - $(this).parent().offset().top;
            $(this).find('img').css({
                //opacity: 1 - ($(window).scrollTop() - $(this).offset().top) / 700,
                top: 0.09 * hmm + 'px'
            });
        });
    }

    core_cover_parallax();
    window.onscroll = function () {
        core_cover_parallax();
    
    };

}, false);